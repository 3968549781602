import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const ProductDetailPage = ({productInfo}) => {
    return (
        <div id="product-detail-page" className="product-detail-page">
            <div className="product-details">
                <div className="title">
                    <h1 className="name">{productInfo.name}</h1>
                </div>

                <h2 className="subtitle">{productInfo.subtitle}</h2>
                <p className="description">{productInfo.description}</p>
                <p className="description">{productInfo.descriptionv2}</p>
                <p className="description">{productInfo.descriptionv3}</p>

            </div>
            <div className="product-image">
                <img src={productInfo.img.img_url} alt={productInfo.img.img_alt}/>
            </div>
        </div>
    )
}

export default ProductDetailPage;
