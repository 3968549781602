import React from 'react'

const Hero = ({data}) => {

    return (
        <div id="hero" className="hero">
            <div className="row hero-content">
                <div className="col-12 col-md-6 hero-image">
                    <img src='./images/garden.png' alt='MatoMato'/>
                </div>
                <div className="col-12 col-md-6 hero-container">
                    <div className="hero-text">
                        <h1>Slimme plantenverzorging </h1>
                        <h1>voor de wereld van morgen!</h1>
                        <p>MatoMato maakt elektronica die helpt bij de verzorging van bomen en planten. Met onze producten helpen we particulieren en bedrijven om inzicht te krijgen in de ontwikkeling van hun gewas.</p>
                        <p>Dit doen we door de omstandigheden en groei te meten en te laten zien aan de eindgebruiker. Zo willen we bijdragen aan een wereld vol groen!</p>
                        <div className="hero-content-buttons">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;
